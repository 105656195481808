import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'helpers'
import { Layout, Section, Container, FormattedMessage } from 'components'

const NotFound = () => (
  <Layout>
    <Section fullpage>
      <Container>
        <h1>
          <FormattedMessage id="notfoundpage-title" />
        </h1>
        <p>
          <FormattedMessage id="notfoundpage-description" />
        </p>
      </Container>
    </Section>
  </Layout>
)

export default injectIntl(NotFound)
